<template>
    <div class="min-vh-100 p-3">
        <!-- toolbar -->
        <table-toolbar
            :title="$tc('trade.product.main', 2)"
        ></table-toolbar>
        <!-- table -->
        <b-table striped hover bordered
                 table-class="text-center"
                 head-row-variant=""
                 table-variant="light"
                 :fields="columns"
                 :items="items"
        >

            <!-- actions head -->
            <!--<template #head(action)="row">
                <b-icon-plus-square
                    style="cursor: pointer;"
                    variant="danger"
                    scale="1.2"
                    v-b-tooltip.hover
                    :title="$t('trade.product.hover_add_contact')"
                    @click="addContact(row)"
                ></b-icon-plus-square>
            </template>-->

            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div>
                    <b-icon-trash
                        style="cursor: pointer;"
                        variant="primary"
                        scale="1.3"
                        v-b-tooltip.hover
                        :title="$t('trade.product.hover_delete_product')"
                        @click="removeProduct(row)"
                    ></b-icon-trash>
                </div>
            </template>

            <!-- products -->
            <template v-slot:cell(name)="row">
                {{row.item.name}}
            </template>

            <template v-slot:cell(description)="row">
                {{row.item.description}}
            </template>

            <template v-slot:cell(ingredients)="row">
                <b-icon font-scale="1.5" variant="primary" icon="check"></b-icon>
            </template>

            <template v-slot:cell(supply)="row">
                {{row.item.supply}}
            </template>

            <template v-slot:cell(certificate)="row">
                {{row.item.certificate}}
            </template>

            <template v-slot:cell(edit)="row">
                <!--                                <b-button size="sm" v-b-modal.modal-1>-->
                <!--                    {{$t('form.edit')}}-->
                <b-icon-file-text
                    size="sm"
                    style="cursor: pointer;"
                    variant="primary"
                    scale="1.3"
                    v-b-tooltip.hover
                    class="align-middle"
                    @click="recordEdit(row.item)"
                >

                </b-icon-file-text>
                <FormProduct></FormProduct>
            </template>
        </b-table>
    </div>
</template>

<script>
import FormProduct from "./ModalFormProduct";
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";

export default {
    name: "Products",
    components: {TableToolbar, FormProduct},
    props: {},
    data() {
        return {
            selected: '',
            columns: [
                {
                    key: 'name',
                    label: this.$t('trade.product.name'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'description',
                    label: this.$t('form.description'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'ingredients',
                    label: this.$tc('trade.product.ingredient', 2),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'supply',
                    label: this.$t('trade.product.supply'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'certificate',
                    label: this.$tc('trade.product.certificate', 1),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'edit',
                    // label: this.$t('form.edit'),
                    sortable: false,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {'vertical-align': 'middle', width: '55px'},
                    tdClass: 'align-middle',
                    tdStyle: {'vertical-align': 'middle'}
                },
            ],

            items: [
                {
                    name: 'Srednji jajčni rezanci',
                    description: 'Rezanci za juho z jajci ...',
                    ingredients: null,
                    phone: '0038641555555',
                    certificate: 'Ekološka kmetija',
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
    },
    methods: {
        removeProduct(row) {
            console.log("removeProduct", row); // TODO brisi
        },
        recordEdit(row) {
            this.$bvModal.show('modalFormProduct');
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
