<template>
    <div>
        <b-table striped hover bordered
                 table-class="text-center"
                 :items="items"
                 head-row-variant=""
                 table-variant=""
                 :fields="columns"
        >
            <template #thead-top="data">
                <b-tr>
                    <b-th colspan="3" variant="secondary">{{$tc('trade.product.packaging', 1)}}</b-th>
                    <b-th colspan="3" variant="danger">{{$t('trade.product.price')}}</b-th>
                    <b-th colspan="1" variant="danger"></b-th>
                </b-tr>
            </template>


            <!-- actions head -->
            <template #head(action)="row">
                <b-icon-plus-square
                    style="cursor: pointer;"
                    variant="info"
                    scale="1.2"
                    v-b-tooltip.hover
                    :title="$t('trade.product.hover_add_packaging')"
                    @click="addPackaging(row)"
                ></b-icon-plus-square>
            </template>

            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-around">
                    <b-icon-trash
                        style="cursor: pointer;"
                        variant="primary"
                        scale="1.3"
                        v-b-tooltip.hover
                        :title="$t('trade.product.hover_delete_packaging')"
                    ></b-icon-trash>
                </div>
            </template>

            <!--              @click="recordDelete(row.item)"-->
            <!--              :title="$t('form.delete')"-->


            <!-- packagings -->
            <template v-slot:cell(quantity)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.quantity"
                        variant="primary"
                        :title="$t('trade.product.quantity_tooltip')"
                        placeholder="..,.."
                        size="sm"
                        class="text-right"
                        type="text"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(unit)="row">
                <div class="d-flex justify-content-around">
                    <b-form-select
                        v-model="row.item.unit"
                        variant="primary"
                        :options="row.field.options"
                        :title="$t('trade.product.unit_tooltip')"
                        size="sm"
                        class=""
                    ></b-form-select>
                </div>
            </template>

            <template v-slot:cell(packaging)="row">
                <div class="d-flex justify-content-around">
                    <b-form-select
                        v-model="row.item.packaging"
                        variant="primary"
                        :options="row.field.options"
                        :title="$t('trade.product.packaging_tooltip')"
                        size="sm"
                        class=""
                    ></b-form-select>
                </div>
            </template>


            <!-- sale -->
            <template v-slot:cell(wholesaleprice)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.wholesaleprice"
                        variant="primary"
                        :title="$t('trade.product.wholesale_price_tooltip')"
                        placeholder="..,.."
                        size="sm"
                        class="text-right"
                        type="text"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(commission)="row">
                <div :title="$t('trade.product.commission_tooltip')">
                    {{row.item.commission}}
                </div>
            </template>

            <template v-slot:cell(retailprice)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.retailprice"
                        variant="primary"
                        :title="$t('trade.product.retail_price_tooltip')"
                        placeholder="..,.."
                        size="sm"
                        class="text-right"
                        type="text"
                    ></b-form-input>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script lang="">
export default {
    name: "FormProductPackaging",
    components: {},
    props: {},
    data() {
        return {
            columns: [
                {
                    key: 'quantity',
                    label: this.$t('trade.product.quantity'),
                    sortable: true,
                    show: true,
                    thStyle: {width: '85px'}
                },
                {
                    key: 'unit',
                    label: this.$tc('trade.product.unit', 0),
                    sortable: true,
                    show: true,
                    options: [
                        {value: null, text: this.$t('form.choose_placeholder'), disabled: true},
                        {value: '1', text: 'kg'},
                        {value: '2', text: 'dag'},
                        {value: '3', text: 'g'},
                    ]
                },
                {
                    key: 'packaging',
                    label: this.$t('trade.product.packaging_material'),
                    sortable: true,
                    show: true,
                    options: [
                        {value: null, text: this.$t('form.choose_placeholder'), disabled: true},
                        {value: '1', text: 'Kišta'},
                        {value: '2', text: 'Vrečka'},
                        {value: '3', text: 'Šopek'},
                    ]
                },
                {
                    key: 'wholesaleprice',
                    label: this.$t('trade.product.wholesale_price'),
                    sortable: true,
                    show: true,
                    thStyle: {width: '15%'},
                },
                {
                    key: 'commission',
                    label: this.$tc('finance.commission', 1),
                    sortable: true,
                    show: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'retailprice',
                    label: this.$t('trade.product.retail_price'),
                    sortable: true,
                    show: true,
                    thStyle: {width: '15%'}
                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {width: '85px'},
                    tdClass: 'align-middle'
                },
            ],

            items: [
                {
                    quantity: 25.3.toLocaleString('sl-SI'),
                    unit: null,
                    packaging: null,
                    wholesaleprice: '200,00',
                    commission: '20 %',
                    retailprice: '200,00'
                },
                {
                    quantity: 25.3.toLocaleString('sl-SI'),
                    unit: null,
                    packaging: null,
                    wholesaleprice: '200,00',
                    commission: '20 %',
                    retailprice: '200,00'
                },
                {
                    quantity: 25.3.toLocaleString('sl-SI'),
                    unit: null,
                    packaging: null,
                    wholesaleprice: '200,00',
                    commission: '20 %',
                    retailprice: '200,00'
                },
                {
                    quantity: 25.3.toLocaleString('sl-SI'),
                    unit: null,
                    packaging: null,
                    wholesaleprice: '200,00',
                    commission: '20 %',
                    retailprice: '200,00'
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
    },
    methods: {
        addPackaging(row) {
            console.log("krneki", row); // TODO brisi
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
