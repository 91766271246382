<template>
    <b-modal
        id="modalFormProduct"
        :title="$tc('trade.product.main', 1)"
        size="xl"
        scrollable
        :ok-title="$t('form.save')"
        :cancel-title="$t('form.cancel')"
    >
        <!-- organisation type -->
        <b-form-row class="justify-content-between align-items-center mr-0">
            <b-col cols="6">
                <b-form-group
                    id="group-supplier_id"
                    class="col-sm-12 col-md-12 float-left"
                    label-class="mb-0"
                    :label="$t('trade.product.supplier')"
                    label-for="supplier_id"
                    description=""
                    :state="valid_supplier_id"
                >
                    <b-form-select
                        id="supplier_id"
                        v-model="record.supplier_id"
                        :options="suppliers"
                        required
                        :state="valid_supplier_id"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col style="height: 35px" cols="6" class="text-right">
                <b-form-checkbox
                    switch
                    id="active"
                    value="true"
                >
                    <b>{{$t('form.publish')}}</b>
                </b-form-checkbox>
            </b-col>
        </b-form-row>

        <b-form ref="form" @submit.prevent="submit">
            <!--         id, active
                            <b-form-group
                                class="mb-0"
                                label-cols="6"
                                :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                                label-size=""
                                label-class="pt-0"
                            >
                              <b-form-checkbox
                                  id="active"
                                  class="float-right mb-1"
                                  switch
                                  v-model="record.active"
                                  name="active"
                                  value="true"
                                  unchecked-value="false"
                              ><b>Objavljeno</b>
                                &lt;!&ndash;                    {{activeText}}&ndash;&gt;
                              </b-form-checkbox>
                            </div>-->

            <!-- left tabs -->
            <b-card no-body>
                <b-tabs pills card no-body vertical>
                    <!-- basic -->
                    <b-tab :title="$t('form.tab.basic')">
                        <b-card-text>
                            ID:
                            <b-form-row>
                                <b-col>
                                    <b-form-group
                                        :label="$t('form.basic.data')"
                                        label-size="md"
                                        label-class="font-weight-bold pt-0"
                                        class="mb-0"
                                    >
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('trade.product.name')}}:</label>
                                            <b-form-input
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_name"
                                                required
                                                v-model="record.basic.name"
                                                :placeholder="$t('trade.product.name_placeholder')"
                                            ></b-form-input>
                                        </b-form>
                                        <b-form>
                                            <label class="mr-2">{{$t('trade.product.type')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_type"
                                                required
                                                v-model="record.basic.type"
                                                :options="data.type"
                                            ></b-form-select>
                                        </b-form>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                        :label="$t('trade.product.mainion')"
                                        label-size="md"
                                        label-class="font-weight-bold pt-0"
                                        class="mb-0"
                                    >
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('trade.product.production_year')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_year"
                                                v-model="record.basic.year"
                                                :options="data.year"
                                            ></b-form-select>
                                        </b-form>
                                        <b-form>
                                            <label class="mr-2">{{$t('trade.product.method')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_method"
                                                v-model="record.basic.method"
                                                :options="data.method"
                                            ></b-form-select>
                                        </b-form>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-group
                                :label="$tc('trade.product.certificate', 1)"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0 mt-4"
                            >
                                <b-form>
                                    <label class="mr-2">{{$tc('trade.product.certificate', 1)}}:</label>
                                    <b-form-select
                                        class="mb-2 mr-sm-2 mb-sm-0"
                                        id="product_certificate"
                                        v-model="record.basic.certificate"
                                        :options="data.certificate"
                                    ></b-form-select>
                                </b-form>
                            </b-form-group>
                        </b-card-text>
                    </b-tab>

                    <!-- description -->
                    <b-tab :title="$t('trade.product.tab.description')">
                        <b-card-text>
                            <vue-editor class="editor-yellow"></vue-editor>
                        </b-card-text>
                    </b-tab>

                    <!-- ingredients -->
                    <b-tab :title="$t('trade.product.tab.ingredient')">
                        <b-card-text>
                            <vue-editor class="editor-red"></vue-editor>
                        </b-card-text>
                    </b-tab>

                    <!-- sale -->
                    <b-tab :title="$t('trade.product.tab.sale')">
                        <b-card-text>
                            <b-form-group
                                :label="$t('trade.product.sale')"
                                label-size="md"
                                label-class="font-weight-bold pt-0"
                                class="mb-0"
                            >
                                <b-form-row>
                                    <b-col>
                                        <b-form class="mb-3">
                                            <label class="mr-3">{{$t('trade.product.offer')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_offer"
                                                required
                                                v-model="record.sale.offer"
                                                :options="data.offer"
                                            ></b-form-select>
                                        </b-form>
                                        <!--                                        <b-form class="mb-3">
                                                                                    <label class="mr-2">{{$t('trade.product.supply')}}:</label>
                                                                                    <b-form inline>
                                                                                        <b-form-input
                                                                                            class="mb-2 mr-sm-2 mb-sm-0"
                                                                                            id="product_quantity"
                                                                                            required
                                                                                            :placeholder="$t('trade.product.supply_placeholder')"
                                                                                        ></b-form-input>
                                                                                        <b-form-select
                                                                                            class="mb-2 mr-sm-2 mb-sm-0"
                                                                                            id="product_quantity_units"
                                                                                            required
                                                                                            v-model="record.sale.unit"
                                                                                            :options="data.unit"
                                                                                        ></b-form-select>
                                                                                    </b-form>
                                                                                </b-form>-->
                                    </b-col>
                                    <b-col>
                                        <b-form>
                                            <label class="mr-3">{{$t('trade.product.tax')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_group"
                                                v-model="record.sale.tax_rate"
                                                :options="data.tax_rate"
                                            ></b-form-select>
                                        </b-form>
                                    </b-col>
                                </b-form-row>

                                <b-form-row class="mt-1">
                                    <form-product-packaging></form-product-packaging>
                                </b-form-row>
                            </b-form-group>
                        </b-card-text>
                    </b-tab>
                    <!-- image -->
                    <b-tab :title="$t('form.tab.picture')">
                        <b-card-text>
                            Forma slike, večjezično
                        </b-card-text>
                    </b-tab>
                    <!-- requirements -->
                    <b-tab :title="$t('trade.product.tab.requirement')">
                        <b-card-text>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        :label="$t('trade.product.temperature_control')"
                                        label-size="md"
                                        label-class="font-weight-bold pt-0"
                                        class="mb-0"
                                    >
                                        <b-form class="mb-3" inline>
                                            <label class="mr-2">{{$t('trade.product.handling')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="product_handling"
                                                v-model="record.requirements.temperature"
                                                :options="data.temperature"
                                            ></b-form-select>
                                        </b-form>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                        :label="$t('trade.product.alcohol')"
                                        label-size="md"
                                        label-class="font-weight-bold pt-0"
                                        class="mb-0"
                                    >
                                        <b-form class="mb-3" inline>
                                            <label class="mr-2">{{$t('trade.product.alcohol_containing')}}:</label>
                                            <b-form-input
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                style="max-width: 70px"
                                                id="product_alcohol"
                                                placeholder="..,."
                                            ></b-form-input>
                                            {{$tc('trade.product.alcohol_containing_unit', 1)}}.
                                        </b-form>
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            name="checkbox-1"
                                            value="not_accepted"
                                        >
                                            {{$tc('trade.product.minimal_legal_age', 1)}}.
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <!-- notes -->
                    <b-tab :title="$t('form.tab.notes')">
                        <b-card-text>
                            <vue-editor class="editor-blue"></vue-editor>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-form>
        <b-alert variant="danger" show dismissible class="mt-3 mb-0">Danger Alert - missing data!</b-alert>
    </b-modal>
</template>

<script>
import FormProductPackaging from "@/components/manager/product/FormProductPackaging";

export default {
    name: "FormProduct",
    components: {FormProductPackaging},
    props: {
        form: {
            valid: false,
            record: {},
        },
        // data: {},
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            data: {
                type: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Jabolka',
                    },
                    {
                        value: 2,
                        text: 'Bučno olje',
                    },
                ],
                year: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: '2020',
                    },
                    {
                        value: 2,
                        text: '2019',
                    },
                ],
                method: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Ekološka pridelava',
                    },
                    {
                        value: 2,
                        text: 'Integrirana pridelava',
                    },
                    {
                        value: 3,
                        text: 'Intenzivna pridelava',
                    },
                ],
                certificate: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Ekološki certifikat IKC',
                    },
                    {
                        value: 2,
                        text: 'Integrirani certifikat',
                    },
                ],
                offer: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Tedenska',
                    },
                    {
                        value: 2,
                        text: 'Količinska',
                    },
                    {
                        value: 3,
                        text: 'Po naročilu',
                    },
                ],
                tax_rate: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: '22 %',
                    },
                    {
                        value: 2,
                        text: '9,5 %',
                    },
                    {
                        value: 3,
                        text: '4 %',
                    },
                    {
                        value: 4,
                        text: 'Oprostitev',
                    },
                ],
                unit: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'kg',
                    },
                    {
                        value: 2,
                        text: 'šopek',
                    },
                ],
                temperature: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Refrigerated',
                    },
                    {
                        value: 2,
                        text: 'Frozen',
                    },
                ],
            },
            record: {
                id: 0,
                active: false,
                order: 0,
                basic: {
                    name: null,
                    type: null,
                    year: null,
                    method: null,
                    certificate: null,
                },
                sale: {
                    offer: null,
                    tax_rate: null,
                    unit: null,
                },
                requirements: {
                    temperature: null,
                },


                supplier_id: null,
                meta: {
                    name: '',
                    name_full: ''
                },
                content: {
                    code: '',
                    domain: '',
                    mark_2: '',
                    mark_3: '',
                    eu_member: false
                },
                locale: {},
            },
            suppliers: [],
            // continents: [],
        }
    },
    mounted() {
        // this.modalBus.$on('submit', this.submit);
        // <-- set foreign data -->
        // this.setContinents();
        // <--  -->
        // if (this.form.record != null) {
        //     this.$set(this, 'record', this.form.record);
        // }
    },
    methods: {
        // <-- set selects data -->
        setContinents() {
            // let continents = [
            //     {value: null, text: '/', disabled: false},
            // ];
            // let meta = false;
            // let locale = {};
            // this.data.continents.forEach((continent, index) => {
            //     meta = false;
            //     locale = continent.locale[this.$i18n.locale];
            //     // <-- if missing locale data -->
            //     if (locale === undefined) {
            //         meta = true;
            //         locale = continent.meta;
            //     }
            //     continents.push(
            //         {
            //             value: continent.id,
            //             text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('name')}) : ''),
            //             disabled: false
            //         }
            //     );
            // });
            // // <--  -->
            // this.$set(this, 'continents', continents);
        },
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        name_full: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        valid_continent() {
            if (this.valid === null) {
                return null;
            }
            return this.record.continent_id > 0 ? null : false;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        valid_supplier_id() {
            // if (this.valid === null) {
            //     return null;
            // }
            // return this.record.meta.name.length > 0 ? null : false;
            return false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
</style>
